import { css } from "@emotion/core";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { animated } from "react-spring";
import {
  colors,
  hexRgba,
  mediaquery,
  rem,
  scaleSpacing,
  zIndexes,
} from "../../../../styles/theme";
import Image from "../../Atoms/Image";
import Button from "../../Atoms/Button";
import Link from "../../Atoms/Link";
import {
  NotoBody,
  NotoLinkSmall,
  NotoSmall,
  OswaldOverline4,
  OswaldH6,
} from "../../Atoms/Typography/Typography";

const DiscoverContainer = styled.div`
  position: absolute;
  top: calc(100% - ${scaleSpacing(2)});
  left: 0;
  right: 0;
  height: 0;
  min-height: 0;
  background: ${colors.black};
  padding: 0 ${scaleSpacing(4)};
  border-radius: 0px 0px ${scaleSpacing(2)} ${scaleSpacing(2)};
  transition: min-height 300ms ease-out;
  overflow: hidden;
`;

const Discover = styled.p`
  ${NotoLinkSmall};
  color: ${colors.white};
  text-decoration: underline;
  padding-top: ${scaleSpacing(2)};
  padding-bottom: ${scaleSpacing(4)};
`;

const CoverContent = styled.div`
  transition: transform 600ms ease-out;
  transform: scale(1);
  width: ${({ hasauthor, haspdf }) => (!hasauthor && !haspdf ? "100%" : "75%")};
  box-shadow: ${({ haspdf }) =>
    haspdf
      ? css`0px ${scaleSpacing(4)} ${scaleSpacing(6)} rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)`
      : "none"};
  ${({ hasauthor, haspdf }) =>
    !hasauthor && !haspdf
      ? css`
          margin: initial;
        `
      : css`
          margin: 0 auto;
        `};
`;

const WidthWrapper = styled(animated.div)`
  height: 100%;
  width: 264px;
  background: ${({ haspdf, hasauthor }) =>
    !haspdf && !hasauthor ? colors.black : "transparent"};
  box-shadow: ${({ haspdf }) =>
    !haspdf
      ? css`0px ${scaleSpacing(4)} ${scaleSpacing(6)} rgba(0, 0, 0, 0.06),
    0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)`
      : "none"};
  border-radius: ${scaleSpacing(2)};
  transition: transform 300ms ease-out;
  ${mediaquery.sm(css`
    width: calc(var(--col) * 10 - var(--gutter-size));
  `)}
  ${mediaquery.md(css`
    width: calc(var(--col) * 7 - var(--gutter-size));
  `)}
  ${mediaquery.xl(css`
    width: calc((100vw - ${scaleSpacing(14)} * 2 - ${scaleSpacing(6)} * 4) / 5);
  `)};

  ${({ animate }) =>
    !!animate &&
    css`
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          transform: translateY(-15px) !important;

          ${DiscoverContainer} {
            min-height: ${scaleSpacing(10)};
          }

          ${CoverContent} {
            transform: scale(1.1);
            transition: transform 300ms ease-out;
          }
        }
      }
    `}
`;

const WrapperOutside = styled(Link)`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  &:hover {
    cursor: pointer;
  }
`;

const WrapperInside = styled.div`
  flex: 1;
  width: 100%;
  padding: 0 ${scaleSpacing(4)};
  display: flex;
  flex-direction: column;
  z-index: ${zIndexes.top};
`;

const Cover = styled.div`
  position: relative;
  width: 100%;
  z-index: ${zIndexes.bottom};
  background-color: ${({ hasauthor, haspdf }) =>
    !hasauthor && !haspdf ? colors.darkgrey2 : "transparent"};
  overflow: hidden;
  border-radius: ${scaleSpacing(2)} ${scaleSpacing(2)} 0 0;
  margin-bottom: ${({ hasauthor, haspdf }) =>
    !hasauthor && !haspdf ? scaleSpacing(3) : 0};

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${zIndexes.middle};
    background: ${({ hasauthor, haspdf }) =>
      !hasauthor && !haspdf ? hexRgba(colors.black, 0.1) : "transparent"};
  }

  img {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.div`
  color: ${colors.white};
  ${NotoBody};
`;

const Subtitle = styled.div`
  color: ${colors.white};
  margin: 0;
  ${NotoSmall};
`;

const List = styled.ul`
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
  margin-top: auto;
  padding-top: ${scaleSpacing(6)};
`;

const ListItem = styled.li`
  display: flex;
  position: relative;
  margin: 0 ${scaleSpacing(4)} ${scaleSpacing(3)} 0;

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    content: "";
    width: 1px;
    height: 9px;
    transform: translate(-8px, 10px);
    background-color: ${colors.mediumgrey3};
    position: absolute;
    right: -${scaleSpacing(4)};
    top: 0;
  }
  &:last-of-type:after {
    display: none;
  }
`;

const tagSTyle = css`
  ${NotoBody};
  line-height: ${rem(16)};
  background-color: transparent;
  color: ${colors.mediumgrey3};
  padding: ${scaleSpacing(1)} 0;
`;

const StyledAuthorName = styled.p`
  ${OswaldH6};
  color: ${({ haspdf }) => (haspdf ? colors.black : colors.white)};
  text-align: center;
  padding: ${scaleSpacing(6)} 0;
  ${mediaquery.sm(css`
    padding: ${scaleSpacing(10)} 0;
  `)}

  ${({ type }) =>
    type === "AuthorDark" &&
    css`
      padding: ${scaleSpacing(4)} 0;
    `}

  ${({ haspdf }) =>
    haspdf
      ? css`
          ${OswaldH6}
        `
      : css`
          ${OswaldOverline4}
        `}
`;

const StyledDescription = styled.p`
  ${NotoBody};
  color: ${colors.mediumgrey2};
  text-align: center;
  background: transparent;
`;

const CardSmall = ({
  link,
  subtitle,
  title,
  image,
  duration,
  genre,
  type,
  free,
  animate,
  animatedStyles,
  authorName,
  pdfDescription,
  pdfDownloadLink,
  pdfTitle,
}) => {
  const { t } = useTranslation();

  return (
    <WidthWrapper
      animate={animate && !authorName && !pdfTitle ? 1 : 0}
      style={animatedStyles}
      haspdf={pdfTitle}
      hasauthor={authorName}
    >
      <WrapperOutside
        linkTheme="area"
        link={pdfDownloadLink || link}
        download={!!pdfDownloadLink}
      >
        <Cover hasauthor={authorName} haspdf={pdfTitle}>
          {image && (
            <CoverContent hasauthor={authorName} haspdf={pdfTitle}>
              <Image source={image} sizes="264px" />
            </CoverContent>
          )}
          {!!free && (
            <Button
              buttonTheme="tagRed"
              css={css`
                position: absolute;
                bottom: ${scaleSpacing(4)};
                left: ${scaleSpacing(4)};
                border-radius: ${rem(15)};
                padding: ${rem(3)} ${rem(8)} ${rem(5)};
                line-height: ${rem(12)};
                text-transform: none;
              `}
            >
              {t("curiosity_herowithcarousel_free")}
            </Button>
          )}
        </Cover>
        {authorName || pdfTitle ? (
          <>
            <StyledAuthorName type={type} haspdf={pdfTitle}>
              {authorName || pdfTitle}
            </StyledAuthorName>
            {pdfDescription && (
              <StyledDescription>{pdfDescription}</StyledDescription>
            )}
          </>
        ) : (
          <>
            <WrapperInside>
              <ContentWrapper>
                {title && <Title>{title}</Title>}
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
                <List>
                  {duration && (
                    <>
                      <ListItem>
                        <Button css={tagSTyle}>{duration}</Button>
                      </ListItem>
                    </>
                  )}
                  {genre && (
                    <ListItem>
                      <Button css={tagSTyle}>{genre}</Button>
                    </ListItem>
                  )}
                </List>
              </ContentWrapper>
            </WrapperInside>
            <DiscoverContainer>
              <Discover aria-hidden="hidden">
                {t("curiosity_cardfilm_discoverlabel")}
              </Discover>
            </DiscoverContainer>
          </>
        )}
      </WrapperOutside>
    </WidthWrapper>
  );
};

CardSmall.propTypes = {
  link: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
  duration: PropTypes.string,
  genre: PropTypes.string,
  type: PropTypes.string,
  pdfDescription: PropTypes.string,
  pdfDownloadLink: PropTypes.object,
  pdfTitle: PropTypes.string,
  authorName: PropTypes.string,
  free: PropTypes.bool,
  animate: PropTypes.bool,
  animatedStyles: PropTypes.object.isRequired,
};

export default React.memo(CardSmall);
